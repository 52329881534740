:root {
  --pageEpg-search-grid-width: 100px;
  --Search_inputWidth: 456px;
}

@media (max-width: 767px) {
  :root {
    --Search_inputWidth: 100%;
  }
}

._16XYM_51nBinc0zMitQotS {
  padding-left: 50px;
  outline: none;
  /* overflow-x: hidden; */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

._16XYM_51nBinc0zMitQotS.QWVjm5hHpnExd6Cp1RmQH {
  padding-left: 0;
  padding-right: 50px;
}

._2Anrioee9NDoNmV3xIgnfw {
  margin: 30px 70px 0 0;
  display: inline-block;
}

.f-OTIrwiFLmcb-3Cn0T9U {
  overflow: auto;
  outline: none;
}

._350NNAXJyWdGkiiY6rWmTs {
  background: var(--color-secondary3);
  color: var(--color-content-default1);
}

.vm4vFoqQtDzvD3DsS28zi,
._350NNAXJyWdGkiiY6rWmTs:hover {
  background-color: var(--color-secondary2);
  color: var(--color-content-highlight-hover);
}

._1T4PMIsw-1MuoQE1XBctw6 {
  background: var(--color-secondary3);
}

._2ak_-Nhs1Zpc3cy9xLwzQd {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px);
}

.CNdNG_yVXvyP6sb-0tlNp {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 10;
  background-color: var(--color-secondary3);
  width: calc(var(--EPGHeaderItem-width) - 20px);
  border-radius: 5px;
  overflow-y: scroll;
  height: 90px;
  overflow: hidden;
  -webkit-box-shadow: 5px 5px 8px 5px rgba(0, 0, 0, 0.24);
  box-shadow: 5px 5px 8px 5px rgba(0, 0, 0, 0.24);
}

._1he_AoMuI3n6Ax2K-e02L3 {
  display: inline-flex;
  align-items: center;
  align-content: center;
  height: 45px;
  width: 100%;
  text-decoration: none;
  color: var(--color-content-default1);
  box-shadow: 3px 1px 7px 0px rgb(0 0 0 / 50%);
}

._1he_AoMuI3n6Ax2K-e02L3:hover {
  background-color: var(--color-highlight-hover1);
  color: var(--color-button-normal-font-hover);
}

._1he_AoMuI3n6Ax2K-e02L3:hover svg {
  fill: var(--color-button-normal-font-hover);
}

._5pkkvXBlYomi3WSTiqSdO {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-left: -20px;
  padding: 0 10px 0 30px;
  height: 40px;
  width: 90%;
  text-decoration: none;
  color: var(--color-content-default1);
  cursor: pointer;
}

._11SMZP1wocamU3Doji0pTp {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-left: -20px;
  padding: 0 10px 0 30px;
  height: 40px;
  width: 90%;
  text-decoration: none;
  color: var(--color-content-default1);
  background-color: var(--color-highlight-hover1);
  cursor: pointer;
}

.konof8UZuZ_x8km0wzTDF > svg,
.r37KkpJc45Q-j41ASdiQ9 > svg,
.ZdTMkqC4YNHBuWZz6_iN1 > svg {
  height: 24px;
  width: 24px;
  margin-left: 10px;
  margin-right: 20px;
  cursor: pointer;
}

.ZdTMkqC4YNHBuWZz6_iN1 > svg,
.konof8UZuZ_x8km0wzTDF > svg {
  fill: var(--color-content-default1);
}

.r37KkpJc45Q-j41ASdiQ9 > svg {
  fill: var(--color-content-highlight-hover);
}

._3qXrSYjpnEpGkSLhfFLJjB {
  overflow: auto;
  outline: none;
  display: -webkit-inline-box;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  direction: ltr;
}

._1hanD01TetVJMB-gHB-thN {
  overflow: auto;
  outline: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 100%;
}

._2thVwKmx0at0dBt-ztNtfx {
  cursor: pointer;
  margin-right: 5px;
}

._2nq64pzPqkld7HpRUkRtxo {
  margin: 0 0 30px 0;
  direction: ltr;
}

/* Hide scrollbar */
.f-OTIrwiFLmcb-3Cn0T9U::-webkit-scrollbar,
._3qXrSYjpnEpGkSLhfFLJjB::-webkit-scrollbar,
._1hanD01TetVJMB-gHB-thN::-webkit-scrollbar {
  display: none !important;
  /* Chrome Safari */
}

.f-OTIrwiFLmcb-3Cn0T9U,
._3qXrSYjpnEpGkSLhfFLJjB,
._1hanD01TetVJMB-gHB-thN {
  -ms-overflow-style: none !important;
  /* IE 10+ */
  overflow: -moz-scrollbars-none !important;
  /* Firefox */
  scrollbar-width: none !important;
  /* Firefox */
}

.xY4LWS7HeE7YJNfkHKNHN {
  display: inline-block;
  min-width: 10px;
  border-radius: 2px;
  background-color: #373737;
  padding: 1px 5px;
}

@media (max-width: 1023px) {
  ._16XYM_51nBinc0zMitQotS {
    padding-left: 20px;
  }

  :root {
    --pageEpg-search-grid-width: 95px;
  }
}

.aNnxG4fGsfSg_7y3x0uK2 {
  display: flex;
  align-items: center;
  align-content: center;
  margin-left: -20px;
  padding: 0 0 0 30px;
  height: 52px;
  width: 90%;
  text-decoration: none;
  color: var(--color-content-default1);
  cursor: pointer;
  width: max-content;
  font-size: 14px;
}

._2AuCDmLInZP7hYxIfbCWdo {
  height: 1px;
  margin: 8px 0 10px -10px;
  opacity: 0.4;
  background-color: var(--color-primary3);
}

._2D3MhGVXIgCz1_De6I3Huk {
  font-size: 14px;
  color: var(--color-primary3);
}

._3zzHNs4Rd_1-FbsbVNA4ai {
  cursor: pointer;
  margin-left: 5px;
}

._1Vyo5sL7OkMP6dBLm6_P4r {
  cursor: pointer;
  width: 20px;
}

._1Vyo5sL7OkMP6dBLm6_P4r path {
  stroke: var(--color-content-default3);
}

._28g6WV3frUFtZT6j79bP3O {
  transform: rotate(-270deg);
}

._11EkIvJfVezkfhJ4f81EoL {
  transform: rotate(270deg);
}

.EKTskcZ8q6pas8dLpvzrk {
  transform: rotate(180deg);
}

.YnXIIfVb885aBh_hMXnHF {
  width: var(--Search_inputWidth);
  display: inline-flex;
  align-items: center;
  margin: 20px 0;
}

._2aI5sb_nWvFYEp7fUnV-SP {
  background: var(--color-nav-background);
}

._1YshPFIWlPn6IN_ftuEyXo {
  align-items: center;
  display: inline-flex;
  position: absolute;
  padding-left: 20px;
}

._1YshPFIWlPn6IN_ftuEyXo._4TcF9nlNAQ0-YjHd3T7T_ {
  right: initial;
  left: initial;
}

._3PPxt_q8RJKTHwWc7OCzKq {
  cursor: pointer;
  width: 24px;
  height: 24px;
  fill: var(--color-search-primary1);
}

._2LCnVHLNraCOjvahmB9cTG {
  width: var(--Search_inputWidth);
  height: 40px;
  border-radius: 20px;
  border: none;
  background-color: var(--color-search-input-bg);
  font-size: var(--fontSize-m);
  color: var(--color-search-primary1);
  box-sizing: border-box;
  outline: none;
  padding-left: 60px;
}

._2LCnVHLNraCOjvahmB9cTG._7RddIbpPtozDvITyOn1uM {
  padding-left: 60px;
  width: 100%;
}

._28g6WV3frUFtZT6j79bP3O {
  transform: rotate(-270deg);
}

._11EkIvJfVezkfhJ4f81EoL {
  transform: rotate(270deg);
}

._1RxZdCwquAmD2qjNy6C9uS {
  min-width: 120px;
  min-height: 120px;
}

._3iGvIaKWgoOV3lgvs5FQmA {
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--pageEpg-search-grid-width), 1fr)
  );
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

@media (max-width: 375px) {
  ._3iGvIaKWgoOV3lgvs5FQmA {
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--pageEpg-search-grid-width), 1fr)
    );
    grid-column-gap: 21px;
    grid-row-gap: 10px;
  }
}
